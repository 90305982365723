<form #projectForm='ngForm' class="d-flex flex-column">
  <mat-label class="label">Project name</mat-label>
  <mat-form-field hideRequiredMarker class="mat-light" appearance="outline">
    <input name="projectName" [(ngModel)]="project.name" #name="ngModel" matInput autocomplete="off"
      smUniqueProjectValidator smUniqueNameValidator [existingNames]="[]" [parent]="targetFolder.value"
      pattern="^[^/]*$" required minlength="3">
  </mat-form-field>
  <div class="bg">
    <mat-error *ngIf="name.touched && name.errors?.required">*Please add name.</mat-error>
    <mat-error *ngIf="name.touched && name.errors?.uniqueProject">*Project name in this path already
      exists.</mat-error>
    <mat-error *ngIf="name.touched && name.errors?.minlength">*Project name should contain more than 3
      characters.</mat-error>
    <mat-error *ngIf="name.touched && name.errors?.emptyName && !name.errors?.minlength">*Project name can't contain
      only spaces.</mat-error>
    <mat-error *ngIf="name.touched && name.errors?.pattern &&  !name.errors?.minlength">*Project name can't contain
      slash (/)</mat-error>
  </div>
  <mat-label class="label">Create in</mat-label>
  <mat-form-field hideRequiredMarker appearance="outline"
    (mousedown)="!isFocused(locationRef) && targetFolder.value && targetFolder.reset(); locationRef.blur(); locationRef.focus()">
    <div class="bg">     
      <input type="text" matInput #targetFolder="ngModel" #locationRef name="location" [(ngModel)]="project.parent"
        [matAutocomplete]="auto" placeholder="Search for project path" required minlength="3">
      <span matSuffix
        *ngIf="projects && targetFolder.value && (!isAutoCompleteOpen) && !(project.parent | stringIncludedInArray:projectsNames)"
        class="creat-new-suffix">(Create New)</span>
      <i matSuffix class="al-icon sm-md search-icon me-2"
        [ngClass]="targetFolder.value? 'al-ico-dialog-x pointer':'al-ico-search'"
        (click)="targetFolder.value && targetFolder.reset(); locationRef.focus()" smClickStopPropagation></i>
      <mat-autocomplete (opened)="setIsAutoCompleteOpen(true)" (closed)="setIsAutoCompleteOpen(false)"
        (optionSelected)="locationSelected($event)" #auto="matAutocomplete" autoActiveFirstOption class="light-theme"
        classList="light-theme">
        <mat-option class="item"
          *ngIf="projectsNames !== null && targetFolder.value && !(targetFolder.value | stringIncludedInArray:projectsNames)"
          [value]="targetFolder.value">"{{targetFolder.value}}" <span class="new">(Create New)</span></mat-option>
        <mat-option *ngFor="let projectName of projectsNames" [value]="projectName" [smTooltip]="projectName"
          smShowTooltipIfEllipsis>
          <div [innerHTML]="projectName | highlightSearchText:targetFolder.value"></div>
        </mat-option>
        <div *ngIf="!projects" style="line-height: 100px; pointer-events: none;">
          <mat-spinner class="m-auto" [diameter]="80" [strokeWidth]="8"></mat-spinner>
        </div>
        <div *ngIf="projects && !noMoreOptions" (smScrollEnd)="!loading && loadMore(targetFolder.value)"
          class="text-center">Loading more...</div>
        <mat-option disabled style="height: 0; min-height: 0;"></mat-option>
        <!-- Empty mat-option, so the autocomplete menu will always pop -->
      </mat-autocomplete>
    </div>
  </mat-form-field>
  <mat-error *ngIf="targetFolder.touched && targetFolder.errors?.required">*Please add location.</mat-error>
  <mat-error *ngIf="targetFolder?.errors?.minlength">Please provide a name longer than 3 characters</mat-error>
  <mat-label class="label">Description</mat-label>
  <mat-form-field hideRequiredMarker appearance="outline">   
    <textarea class="project-description" name="description" matInput [(ngModel)]="project.description"
      #description="ngModel"></textarea>
  </mat-form-field>
  <mat-error *ngIf="description?.touched && description?.invalid">*Please add description.
  </mat-error>
  <mat-label class="label">Default output destination</mat-label>
  <mat-form-field appearance="outline">   
    <input class="project-output-dest" matInput name="default_output_destination"
      [(ngModel)]="project.default_output_destination" #default_output_destination="ngModel"
      [pattern]="outputDestPattern" placeholder="e.g. s3://bucket. gs://bucket">
  </mat-form-field>
  <mat-error *ngIf="default_output_destination?.touched && default_output_destination?.errors?.required">*Please add
    default destination.
  </mat-error>
  <mat-error *ngIf="default_output_destination?.touched && default_output_destination?.errors?.pattern">*Illegal
    output destination (e.g. s3://bucket. gs://bucket)
  </mat-error>
  <div class="row">
    <div class="col-24 create-project-button">
      <button class="btn btn-cml-primary center" data-id="Create Project" [disabled]="projectForm.invalid"
        (click)="send()">Create Project
      </button>
    </div>
  </div>
</form>